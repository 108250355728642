import { initializeApp } from "@firebase/app";
import {
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    User
} from "@firebase/auth";
import type {
    AuthErrorResponse,
    AuthReadyResponse,
    AuthRequest,
    AuthResponse,
    AuthStateChangeMessage,
    AuthUserResponse,
    UserInfo
} from "@lib/auth/types.js";

const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const PROVIDER = new GoogleAuthProvider();
PROVIDER.addScope("profile");
PROVIDER.addScope("email");
PROVIDER.setCustomParameters({
    prompt: "select_account"
});

const PARENT_FRAME = document.location.ancestorOrigins[0];

const getUserInfo = (user: User): UserInfo => ({
    displayName: user.displayName,
    email: user.email,
    uid: user.uid
});

async function login(messageId: string): Promise<AuthUserResponse> {
    try {
        const response = await signInWithPopup(auth, PROVIDER);
        const user = response.user;
        return {
            type: "auth-user",
            idToken: await user.getIdToken(),
            user: getUserInfo(user),
            messageId
        };
    } catch (error) {
        console.error("Login error:", error);
        throw error;
    }
}

async function logout(): Promise<void> {
    try {
        await signOut(auth);
    } catch (error) {
        console.error("Logout error:", error);
        throw error;
    }
}

// FIXME This is not working as expected
window.addEventListener("load", () => {
    window.parent.postMessage(
        { type: "auth-ready" } satisfies AuthReadyResponse,
        PARENT_FRAME
    );
});

window.addEventListener("message", async function (event: MessageEvent<AuthRequest>) {
    const data = event.data;
    const messageId = data.messageId;
    try {
        let result: AuthResponse;
        const currentUser = auth?.currentUser;
        switch (data.type) {
            case "auth-login":
                result = await login(messageId);
                break;
            case "auth-logout":
                await logout();
                result = {
                    type: "auth-logout",
                    success: true,
                    messageId
                };
                break;
            case "auth-get-user":
                const forceRefresh = data?.forceRefresh;
                result = {
                    type: "auth-user",
                    idToken: currentUser
                        ? await currentUser.getIdToken(forceRefresh)
                        : null,
                    user: currentUser ? getUserInfo(currentUser) : null,
                    messageId
                };
                break;
        }
        window.parent.postMessage(result satisfies AuthResponse, PARENT_FRAME);
    } catch (error) {
        window.parent.postMessage(
            { type: "auth-error", messageId, error } satisfies AuthErrorResponse,
            PARENT_FRAME
        );
    }
});

auth.onAuthStateChanged(async user => {
    // FIXME Weirdly this is working to send the initial auth state of the user
    //       during extension loading to render initial content menu item
    window.parent.postMessage(
        { type: "auth-ready" } satisfies AuthReadyResponse,
        PARENT_FRAME
    );
    window.parent.postMessage(
        {
            type: "auth-state-change",
            user: user ? getUserInfo(user) : null,
            idToken: user ? await user.getIdToken() : null
        } satisfies AuthStateChangeMessage,
        PARENT_FRAME
    );
});
